
import IconClose from '@/components/svg/IconClose.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * PromptYesNo.vue is a generic modal yes/no style prompt.
 * It has named slots "title" and "content" and props for populating the dialog at the parent level.
 * Call ShowDialog() to display the modal dialog prompt and optionally supply an item with an id.
 * The events "on-answer-yes" or "on-answer-no" are emitted based on the user selection allowing the parent to respond
 * and take action.
 */
@Component({
  components: {
    'icon-close': IconClose,
  },
})
export default class PromptYesNoVue extends Vue {
  @Prop({ default: false })
  public noLinkCta!: boolean
  @Prop({ default: '' })
  public noLinkHref!: string
  @Prop({ default: '' })
  public noLinkTarget!: string
  @Prop({ default: false })
  public yesLinkCta!: boolean
  @Prop({ default: '' })
  public yesLinkHref!: string
  @Prop({ default: '' })
  public yesLinkTarget!: string
  @Prop({ default: 'Ok' })
  public yesBtnMsg!: string
  @Prop({ default: 'Cancel' })
  public noBtnMsg!: string
  @Prop({ default: 'primary' })
  public yesBtnColor!: string
  @Prop({ default: 'cancel' })
  public noBtnColor!: string
  @Prop({ default: '' })
  public yesBtnClass!: string
  @Prop({ default: '' })
  public noBtnClass!: string
  @Prop({ default: false })
  public yesBtnTextStyle!: boolean
  @Prop({ default: true })
  public noBtnTextStyle!: boolean
  @Prop({ default: true })
  public contentValid!: boolean
  @Prop({ default: false })
  public isYesBtnOutlined!: boolean
  @Prop({ default: true })
  public hasDivider!: boolean
  @Prop({ default: false })
  public isCtaCenterAligned!: boolean
  @Prop({ default: true })
  public requireCtas!: boolean
  @Prop({ default: undefined })
  public yesClickEvent!: Function
  @Prop({ default: undefined })
  public noClickEvent!: Function
  @Prop({ default: false })
  public isProcessing!: boolean

  protected dialogVisible: boolean = false

  public get isDialogActive(): boolean {
    return this.dialogVisible
  }

  public ShowPrompt() {
    this.dialogVisible = true
  }

  public ClosePrompt() {
    this.dialogVisible = false
  }

  protected onModalBtnClick(yes: boolean) {
    if (yes && typeof this.yesClickEvent === 'function') {
      this.yesClickEvent()
    } else if (yes) {
      this.$emit('on-answer-yes')
      this.dialogVisible = false
    } else if (!yes && typeof this.noClickEvent === 'function') {
      this.noClickEvent()
    } else {
      this.$emit('on-answer-no')
      this.dialogVisible = false
    }
  }
}
