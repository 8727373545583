
import EmbedCodeTab from '@/components/share/EmbedCodeTab.vue'
import PermissionTab from '@/components/share/PermissionTab.vue'
import PrivateShareWarning from '@/components/share/PrivateShareWarning.vue'
import QrCodeTab from '@/components/share/QrCodeTab.vue'
import ShareableLink from '@/components/share/ShareableLink.vue'
import ShareWarningPrompt from '@/components/share/ShareWarningPrompt.vue'
import {
  CloseSharePanelFunction,
  LoadJigDetailsFunction,
  ResetTeamSharePermissionsFunction,
  TriggerSharePanelFunction,
} from '@/components/share/type'
import { JigConst, MueConst } from '@/constants'
import JigLock from '@/mixin/JigLock'
import JigPermissions from '@/mixin/JigPermissions'
import JigSharelink from '@/mixin/JigSharelink'
import { Tenant } from '@/store/modules/app/types'
import { JigMetadata, JigVisibilities, JigVisibilityEventData } from '@/store/modules/jig/types'
import { Namespace } from '@/store/types'
import { eventBus } from '@/utils/eventBus'
import { segmentEventTracking } from '@/utils/tracking'
import { mixins } from 'vue-class-component'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

/**
 * The ShareModal component is the share modal popup triggered by `share` icon on JigListing page.
 * It is also used in JigDetails page to manage 1:1 permission.
 *
 * ShareModal includes following functinalities:
 * - 1. Update Jig visibilities (`link` / `private` / `link-password`)
 * - 2. Copy shareable link (DeepLink) and/or Jig password if available
 * - 3. Permission tab:
 *   - a. Update Jig team share config (team can view/re-share/edit)
 *   - b. Manage 1:1 permission of the Jig
 * - 5. Embed tab: config and copy Jig iframe embed code
 * - 6 QR Code tab: view and download QR code
 *
 * Components included:
 * - 1. ShareableLink: handles Jig visibility updates and DeepLink / Jig Password copy
 * - 2. Dynamic components: PermissionTab / EmbedCodeTab / QrCodeTab
 *      Renders dynamically as content of <v-tab-item>
 * - 3. Couple warning prompts before user can copy DeepLink
 */
@Component({
  components: {
    'shareable-link': ShareableLink,
    'qr-code-tab': QrCodeTab,
    'embed-code-tab': EmbedCodeTab,
    'permission-tab': PermissionTab,
    'private-share-warning': PrivateShareWarning,
    'share-warning-prompt': ShareWarningPrompt,
  },
})
export default class ShareModal extends mixins(JigLock, JigPermissions, JigSharelink) {
  @Action('updateJigMetadata', { namespace: Namespace.Jig })
  public updateJigMetadata: any
  @Action('updateJigVisibility', { namespace: Namespace.Jig })
  public updateJigVisibility: any
  @Action('loadJigMetadata', { namespace: Namespace.Jig })
  public loadJigMetadata: any
  @Action('loadJigPermissions', { namespace: Namespace.Jig })
  public loadJigPermissions: any
  @Action('loadTenantUsers', { namespace: Namespace.App })
  public loadTenantUsers: any
  @Action('updateJigPermission', { namespace: Namespace.Jig }) public updateJigPermission: any
  @Action('deleteJigPermission', { namespace: Namespace.Jig }) public deleteJigPermission: any

  // Dynamic components in this file are rendered via v-for,
  // v-for creates an array of components.
  // When we render components dynamically with v-for,
  // each instance is put into an array under the corresponding $refs key.
  // Hence these dynamic components has array type and
  // public functions should be accessed as `$refs.dynamicCompoennt[0].PublicFunction`
  public $refs!: Vue['$refs'] & {
    [key: string]: any
    permissionTab: PermissionTab[]
    embedCodeTab: EmbedCodeTab[]
    qrCodeTab: QrCodeTab[]
    shareableLink: ShareableLink
    shareWarningPrompt: ShareWarningPrompt
  }

  protected dialogVisible: boolean = false
  private closedByOutside: boolean = false
  private currentShareTab: number = 3
  private shareTabs: { id: string; title: string; component: string; isAvailabelForMultiShare: boolean }[] = [
    {
      id: 'permissionTab',
      title: 'Share',
      component: 'permission-tab',
      isAvailabelForMultiShare: true,
    },
    {
      id: 'embedCodeTab',
      title: 'Embed',
      component: 'embed-code-tab',
      isAvailabelForMultiShare: false,
    },
    {
      id: 'qrCodeTab',
      title: 'QR Code',
      component: 'qr-code-tab',
      isAvailabelForMultiShare: false,
    },
  ]
  private jigItem: JigMetadata | null = null
  private jigItems: JigMetadata[] = []
  private isDifferentJig: boolean = false
  private jigSelectionDiffs: boolean = false
  private isAdvancedPermissionTabActive: boolean = false
  private visibilityLink: string = JigConst.JigVisibilities.Link
  private permissionTabInitPayload: any = null
  private isInitialLoading: boolean = true
  private isDataRefreshRequired: boolean = false
  private updateResults: any = {}
  private infoAlert: any = {
    isActive: false,
    copy: '',
    innerHtml: '',
    cta: {
      isRequired: false,
      isPrimary: false,
      onCtaClick: this.defaultResetBehaviour,
      copy: 'Cancel',
    },
  }
  private copyPayload: any = {}
  private shareMode: string = JigConst.jigShareModalMode.default
  private manageUserFullfilled: boolean | null = null

  @Watch('myTenant')
  private async onMyTenantChanged(value: Tenant) {
    this.myTenantID = value.ID

    await this.$nextTick()
    this.updateMyTenantIndex(this.myTenantID)
    await this.loadTenantUsers()

    if (!this.isInitialLoading) {
      this.isDataRefreshRequired = true
    }
  }

  @Watch('dialogVisible')
  private async onDialogVisibleChange(value: boolean) {
    if (!value && this.isAdvancedPermissionTabActive) {
      await this.hideAdvancedPermissionTab()
    }
    if (this.closedByOutside && !value) {
      this.resetModalActions()
      this.closedByOutside = false
    }
  }

  protected mounted() {
    eventBus.$on(this.rootJigEvents.onCopyShareableLink, (payload: any) => {
      this.onCopyShareableLink(payload)
    })
  }

  protected beforeDestroy() {
    eventBus.$off(this.rootJigEvents.onCopyShareableLink)
  }

  private get isMultiJigShare(): boolean {
    return this.jigItems.length > 1
  }

  private get availableTabs(): any[] {
    let tabs: Array<any> = []
    if (this.isMultiJigShare) {
      tabs = this.shareTabs.filter((tab) => tab.isAvailabelForMultiShare)
    } else {
      tabs = this.shareTabs
    }

    return tabs
  }

  private get isSelectedJigChanged(): boolean {
    return (this.jigItems.length === 0 && this.isDifferentJig) || (this.jigItems.length > 0 && this.jigSelectionDiffs)
  }

  protected async onModalClose() {
    if (this.shareMode !== JigConst.jigShareModalMode.manageUser && this.isAdvancedPermissionTabActive) {
      await this.hideAdvancedPermissionTab()
    } else {
      this.resetModalActions()
      this.dialogVisible = false
    }
  }

  private clickedOutside() {
    this.closedByOutside = true
  }

  private resetModalActions() {
    if (this.updateResults.isSuccess === false) {
      this.isDataRefreshRequired = true
    }

    this.$emit('on-close', { updateResults: this.updateResults.isSuccess })

    this.infoAlert.isActive = false
    if (!this.isMultiJigShare) {
      this.$refs.shareableLink.ResetSelectField()
    }
    // Reset active tab to tab 1.
    // Vuetify2 hide v-dialog with 200ms transition time, to avoid flickerly, delay tab reset slightly
    setTimeout(() => (this.currentShareTab = 0), 150)
  }

  public HidePrompt() {
    this.dialogVisible = false
  }

  public async ShowPrompt(items: JigMetadata[], options: any = { action: '' }) {
    this.isProcessing = true
    this.dialogVisible = true
    this.isDifferentJig = items.length === 1 && (this.jigItem == null || this.jigItem.Id !== items[0].Id)
    this.jigSelectionDiffs =
      items.length > 1 &&
      (!this.jigItems.length || this.jigItems.find((jig: JigMetadata | any) => !items.find((item) => item.Id === jig.Id))) !== null

    if (items.length === 1) {
      this.jigItem = items[0]
      this.jigItems = []
      this.updateLockPayloadJigId(this.jigItem.Id)
    } else {
      this.jigItem = null
      this.jigItems = items
      this.updateLockPayloadJigId('')
    }

    if (
      this.isSelectedJigChanged ||
      this.isDataRefreshRequired ||
      !this.jigMetadata.OwnerTenantID ||
      (this.jigMetadata.ProjectVisibility === JigConst.JigVisibilities.Password && this.jigMetadata.Password == null)
    ) {
      if (this.$route.name !== 'Jig detail') {
        await this.loadJigMetadata({ jigId: items[0].Id })
        this.jigItem = this.jigMetadata
        await this.loadJigPermissions({
          jigId: this.jigItem.Id,
        })
      }

      await this.loadSharePanelContent(items.length > 1)
    }

    switch (options.action) {
      case JigConst.jigShareModalMode.manageUser:
        this.shareMode = JigConst.jigShareModalMode.manageUser
        this.manageUserFullfilled = this.$callRefMethod<TriggerSharePanelFunction>('permissionTab', 'TriggerSharePanel')

        // Init process is finished only if request action is fullfilled.
        // If not we need to wait init action to be done after requested component is mounted
        if (this.manageUserFullfilled) {
          this.isProcessing = false
        }
        break
      default:
        this.shareMode = JigConst.jigShareModalMode.default
        // By default init progress has finished at end of this block
        this.isProcessing = false
        break
    }
    this.isInitialLoading = false
  }

  private defaultResetBehaviour() {
    if (location) {
      location.reload()
    } else if (window.location) {
      window.location.reload()
    }
  }

  private async loadSharePanelContent(skipIndividualPermissionCheck: boolean) {
    this.myTenantID = this.myTenant.ID
    await this.$nextTick()
    this.permissionTabInitPayload = {
      myTenantID: this.myTenantID,
      skipIndividualPermissionCheck,
    }

    const isSuccessful = this.$callRefMethod<LoadJigDetailsFunction>('permissionTab', 'LoadJigDetails', this.permissionTabInitPayload)

    if (isSuccessful) {
      this.permissionTabInitPayload = null
    }
  }

  private async hideAdvancedPermissionTab() {
    await this.$nextTick()
    this.$callRefMethod<CloseSharePanelFunction>('permissionTab', 'CloseSharePanel')
    this.isAdvancedPermissionTabActive = false
  }

  private async onWarningPromptShare(payload: any) {
    const { deeplink, showSuccessMessage, activity, event } = this.copyPayload
    await this.copyShareableLink(deeplink, showSuccessMessage, activity, event)
    this.copyPayload = {}
  }

  private onWarningPromptClosed() {
    this.isProcessing = false
  }

  private onCopyShareableLink(payload: any) {
    this.$refs.shareWarningPrompt.SetWarningPromptKey(payload.visibility)
    this.copyPayload = { ...payload.data }
    this.$refs.shareWarningPrompt.ShowPrompt()
  }

  private getShareableLinkProps() {
    if (this.$route.name === 'Jig detail') {
      return {
        jig: this.jigMetadata,
      }
    } else {
      return {
        jig: this.jigItem,
      }
    }
  }

  private getComponentProps(componentName: string) {
    const props: any = {}

    if (this.jigItem == null) {
      return props
    }

    switch (componentName) {
      case 'qr-code-tab':
        props.jigId = this.jigItem.Id
        break
      case 'embed-code-tab':
        props.jigData = {
          jigId: this.jigItem.Id,
          jigName: this.jigItem.ProjectName,
          jigDeeplink: this.jigItem.DeeplinkURL,
        }
        props.tierType = this.myTenant.Subscription ? this.myTenant.Subscription.TierType : ''
        break
      case 'permission-tab':
        props.jigIds = this.jigItems.length ? this.jigItems.map((jig: JigMetadata) => jig.Id) : [this.jigItem.Id]
        props.jigOwnerUid = this.jigItems.length ? this.jigItems[0].Uid : this.jigItem.Uid
        props.readonlyJig = this.jigItems.length ? false : !this.canEditOrDeleteJig(this.jigItem)
        props.permissionDelete = this.deleteJigPermission
        props.permissionUpdate = this.updateJigPermission
        props.isLoadingConfig = this.isProcessing
        break
      default:
        break
    }

    return props
  }

  private getComponentEvents(componentName: string) {
    const events: any = {}

    switch (componentName) {
      case 'qr-code-tab':
        // At the moment we don't track this event in Segment/Hubspot yet. When we start to track it uncomment below to activate.
        // events['on-qr-modal-downloaded'] = this.onSuccessfulQRCodeAction
        break
      case 'embed-code-tab':
        break
      case 'permission-tab':
        events[this.jigEvents.onUpdatingJigVisibility] = this.onUpdatingJigVisibility
        events[this.jigEvents.onUpdateJigShareConfig] = this.onUpdateJigShareConfig
        events[this.jigShareEvents.onAdvancedPermissionTriggered] = this.triggerAdvancedPermissionTab
        events[this.jigPermissionEvents.onPermissionUpdateCallback] = this.onPermissionUpdateCallback
        events[this.jigPermissionEvents.onInviteSuccessful] = this.onInviteSuccessful
        events[this.jigPermissionEvents.jigPermissionEvents] = this.onInviteFailed
        events[this.jigPermissionEvents.onPermissionUpdateStart] = this.onPermissionUpdate
        events[this.jigPermissionEvents.onBulkInviteSuccessful] = this.onPermissionUpdate
        events[this.jigPermissionEvents.onEditorUpdateSuccessful] = this.onPermissionUpdate
        events[this.jigPermissionEvents.onDeletePermissionSuccessful] = this.onPermissionUpdate
        events[this.jigPermissionEvents.onPermissionUpdateError] = this.onPermissionUpdate
        events[this.jigShareEvents.onPermissionTabMounted] = this.onPermissionTabMounted
        events[this.jigShareEvents.onPermissionTabUpdated] = this.onPermissionTabUpdated
        break
      default:
        break
    }

    return events
  }

  protected async onCopySucceeded(payload: any) {
    this.$emit('on-copy-succeeded', payload)
  }

  private onSuccessfulQRCodeAction() {
    this.$refs.shareableLink.emitSuccess('SavedToDisk')
  }

  private showSUPErrorInfoAlert(copy: string) {
    this.infoAlert.isActive = true
    this.infoAlert.copy = copy
    this.infoAlert.cta.isRequired = true
    this.infoAlert.cta.copy = 'Refresh'
    this.infoAlert.cta.onCtaClick = this.onModalClose
  }

  private async onUpdatingJigVisibility(payload: JigVisibilityEventData) {
    this.isProcessing = true

    this.originalJigTeamSharePermissions = this.jigMetadata.Tenants ? [...this.jigMetadata.Tenants] : undefined
    this.isTeamShareConfigChange = payload.teamSharePermissions != null

    if (!this.isMultiJigShare) {
      const isLockObtained = await this.RequestNewLock()

      if (!isLockObtained) {
        if (this.isTeamShareConfigChange) {
          // reset team share
          this.$callRefMethod<ResetTeamSharePermissionsFunction>('permissionTab', 'ResetTeamSharePermissions')
        } else {
          this.$refs.shareableLink.ResetVisibility()
        }

        return
      }
    }

    if (payload.visibility) {
      this.jigMetadata.ProjectVisibility = payload.visibility
    }

    if (this.isTeamShareConfigChange) {
      this.craftJigTeamSharingData(payload)

      this.updateResults = await this.updateJigMetadata({
        jigMetadata: this.jigMetadata,
        defaultErrorHandleRequired: this.isTeamShareConfigChange,
      })
    } else {
      const visibilityPayload: JigVisibilities = {
        jigId: this.jigMetadata.Id,
        visibility: payload.visibility as string,
        regenPassword: payload.regeneratePassword as boolean,
        version: this.jigMetadata.Version,
      }
      this.updateResults = await this.updateJigVisibility(visibilityPayload)
    }

    if (!this.updateResults.isSuccess) {
      // Error has happened, terminate here.
      this.isProcessing = false

      if (this.updateResults.status === MueConst.nonSystemErrors.unprocessableEntity.status) {
        // 409 error is not caused by our service, hence we only show an info alert message instead of showing general API Error modal.
        this.showSUPErrorInfoAlert(MueConst.nonSystemErrors.unprocessableEntity.copy)
      }

      if (this.isTeamShareConfigChange) {
        // reset team share
        this.resetTeamSharePermissions()
        this.$callRefMethod<ResetTeamSharePermissionsFunction>('permissionTab', 'ResetTeamSharePermissions')
      } else {
        this.$refs.shareableLink.ResetVisibility()
      }

      this.onUpdateJigShareConfig({ permissionsDirty: true })
      await this.releaseLock(this.lockPayload)

      return
    } else {
      this.infoAlert.isActive = false
    }

    if (payload.visibility === JigConst.JigVisibilities.Password) {
      segmentEventTracking('SettingsPasswordSharingEnabled', {
        tenantName: this.myTenant.Name,
        tenantId: this.myTenant.ID,
        jigName: this.jigMetadata.ProjectName,
        jigId: this.jigMetadata.Id,
        visibility: this.jigMetadata.ProjectVisibility,
      })

      if (payload.regeneratePassword) {
        segmentEventTracking('SettingsPasswordRegenerated', {
          tenantName: this.myTenant.Name,
          tenantId: this.myTenant.ID,
          jigName: this.jigMetadata.ProjectName,
          jigId: this.jigMetadata.Id,
          visibility: this.jigMetadata.ProjectVisibility,
        })
      }
    }

    if (!this.isMultiJigShare) {
      await this.releaseLock(this.lockPayload)
    }

    if (!this.isTeamShareConfigChange || payload.teamSharePermissions == null || payload.teamSharePermissions.length === 0) {
      this.onUpdateJigShareConfig({ permissionsDirty: true })
    }

    if (typeof payload.callback === 'function') {
      if (payload.teamSharePermissions) {
        payload.callback({ currentPermission: this.currentPermission, newTeamPermissions: this.newTeamPermissions })
      } else {
        payload.callback()
      }
    }

    await this.loadJigMetadata({ jigId: this.jigMetadata.Id })

    this.isProcessing = false
    this.resetTeamSharePermissions()
  }

  private triggerAdvancedPermissionTab() {
    this.isAdvancedPermissionTabActive = true
  }

  private onUpdateJigShareConfig(payload: any) {
    this.$emit(this.jigEvents.onUpdateJigShareConfig, payload)
  }

  private onInviteSuccessful(payload: any) {
    this.$emit(this.jigPermissionEvents.onInviteSuccessful, payload)
    this.isProcessing = payload.isLoadingConfig
  }

  private onInviteFailed(payload: any) {
    this.$refs.shareWarningPrompt.SetWarningPromptKey(payload.promptKey)
    this.$refs.shareWarningPrompt.SetWarningPromptCopy(payload.failedInvitesCopy)
    this.$refs.shareWarningPrompt.ShowPrompt()
  }

  private async onPermissionUpdate(payload: any) {
    this.isProcessing = payload.isLoadingConfig

    if (payload.jigId) {
      await this.loadJigMetadata({ jigId: payload.jigId })
    }

    if (typeof payload.callback === 'function') {
      payload.callback()
    }

    if (payload.hideAdvancedPermissionTab) {
      await this.hideAdvancedPermissionTab()
    }

    if (payload.closeModal) {
      await this.onModalClose()
    }
  }

  private async onPermissionUpdateCallback(callback: Function) {
    if (this.jigItem == null) {
      return
    }

    await this.loadJigPermissions({
      jigId: this.jigItem.Id,
    })

    if (typeof callback === 'function') {
      callback()
    }
  }

  // Fallback initial treatment, in case permission-tab child component hasn't finished mounting when referenced in parent mounted() lifecycle.
  private async onPermissionTabMounted(payload: any) {
    if (this.isProcessing && this.permissionTabInitPayload) {
      const isSuccessful = this.$callRefMethod<LoadJigDetailsFunction>('permissionTab', 'LoadJigDetails', this.permissionTabInitPayload)
      if (isSuccessful) {
        this.permissionTabInitPayload = null
        this.isProcessing = false
      }
    }

    // By default manageUserFullfilled should be null, if manageUserFullfilled is a boolean value,
    // `true` means `Manage user` action is requested and fullfilled successfully, `false` means not fullfilled as permissionTab is not rendered yet.
    if (this.manageUserFullfilled === false) {
      this.$callRefMethod<TriggerSharePanelFunction>('permissionTab', 'TriggerSharePanel')
      this.manageUserFullfilled = null
    }
  }

  // Make sure initial treatment is refreshed when permission-tab content is updated, for example when user list has been refreshed.
  private async onPermissionTabUpdated(payload: any) {
    if (this.isProcessing && this.permissionTabInitPayload) {
      const isSuccessful = this.$callRefMethod<LoadJigDetailsFunction>('permissionTab', 'LoadJigDetails', this.permissionTabInitPayload)
      if (isSuccessful) {
        this.permissionTabInitPayload = null
        this.isProcessing = false
      }
    }
  }

  public async OnPrivateShareWarningTriggered(payload: any) {
    segmentEventTracking(payload.eventName, {
      tenantName: this.myTenant.Name,
      tenantId: this.myTenant.ID,
      jigName: this.jigMetadata.ProjectName,
      jigId: this.jigMetadata.Id,
    })
    this.$refs.shareWarningPrompt.SetWarningPromptKey(payload.promptKey)
    this.$refs.shareWarningPrompt.ShowPrompt()
  }

  private async onPasswordRegenerateTriggered(payload: any) {
    this.$refs.shareWarningPrompt.SetWarningPromptKey(payload.promptKey)
    this.$refs.shareWarningPrompt.SetPasswordRegeneratedWarningData(
      `The current password is <span class="text--password">${this.jigMetadata.Password}</span>. Would like to generate a new one?`,
      {
        'on-answer-yes': async () => {
          await this.onUpdatingJigVisibility({
            ...payload,
            regeneratePassword: true,
          })
        },
        'on-answer-no': async () => {
          await this.onUpdatingJigVisibility({
            ...payload,
            regeneratePassword: false,
          })
          this.onWarningPromptClosed()
        },
      }
    )

    this.$refs.shareWarningPrompt.ShowPrompt()
  }
}
