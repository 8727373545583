
/* eslint-disable-next-line lines-around-comment */
/**
 * This Vue component is responsible for Jig Transfering. Relevant users can select target user, target tenant and transfer target Jig
 *
 * Key features:
 * - 1. Auto select current owner tenant and owner user
 * - 2. Select new owner tenant and new owner user
 * - 3. Transfer Jig
 *
 * This component provides:
 * - 1. `on-jig-ownership-changed` event emit as soon as ownership is changed successfully.
 * - 2. Public methods: ShowPrompt, ClosePrompt
 */

import UserAvatar from '@/components/image/UserAvatar.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import IconInfo from '@/components/svg/IconInfoFilled.vue'
import { JigConst } from '@/constants'
import { JigOwnerUserListMap, Tenant, TenantUser, UserTenantListMap } from '@/store/modules/app/types'
import { ChangeJigOwnerRequest, ChangeJigOwnerResult, JigTransferMetadata } from '@/store/modules/jig/types'
import { Namespace } from '@/store/types'
import { ValidationRules } from '@/utils/input-validation'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'

const defaultJigBaseData: JigTransferMetadata = {
  id: '',
  ownerTenantID: TenantHelpers.InvalidTenantID,
  uid: 0,
  projectName: '',
}
const defaultTeamOption: UserTenantListMap = {
  text: 'Select team',
  value: TenantHelpers.InvalidTenantID,
}
const defaultTeamUserOption: JigOwnerUserListMap = {
  text: 'Select team member',
  value: '',
  uid: 0,
}

@Component({
  components: {
    'prompt-yes-no': PromptYesNo,
    'user-avatar': UserAvatar,
    'icon-info': IconInfo,
  },
})
export default class TransferJigPromptYesNo extends Vue {
  @Prop({ type: Object, default: {} })
  public jigTransferMeta!: JigTransferMetadata

  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('changeJigOwnerResult', { namespace: Namespace.Jig })
  public changeJigOwnerResult!: ChangeJigOwnerResult
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers!: TenantUser[]
  @State('tenantsUsersMap', { namespace: Namespace.App })
  public tenantsUsersMap!: Map<number, TenantUser[]>

  @Getter('userTenantsDropdownMap', { namespace: Namespace.App })
  public userTenantsDropdownMap!: UserTenantListMap[]

  @Action('changeJigOwner', { namespace: Namespace.Jig }) public changeJigOwner: any
  @Action('changeJigOwnerTenant', { namespace: Namespace.Jig })
  public changeJigOwnerTenant: any
  @Action('loadTenantUsers', { namespace: Namespace.App }) public loadTenantUsers: any

  private jigBaseData: JigTransferMetadata = defaultJigBaseData
  private isSubmitting: boolean = false
  private isLoadingTenantUsers: boolean = false
  private transferJigPromptIsValid: boolean = false
  private teamRules!: any
  private teamUserRules!: any
  private error: boolean = false
  private selectOwnerTeam: UserTenantListMap = defaultTeamOption
  private selectOwnerTeamMember: JigOwnerUserListMap = defaultTeamUserOption
  private isTransferingJig: boolean = false

  public $refs!: Vue['$refs'] & {
    transferJigPromptYesNo: PromptYesNo
  }

  // when jig hashID changed, reload selected owner team and owner user.
  @Watch('jigTransferMeta')
  private async onTransferMetaChanged(value: JigTransferMetadata, oldValue: JigTransferMetadata) {
    if (this.jigTransferMeta == null) {
      return
    }

    if (value != null && value.id !== '') {
      this.jigBaseData = this.jigTransferMeta

      // Make sure it does not overwrite the selected user logic in `selectOwnerTeam` watch function
      if (!this.isLoadingTenantUsers) {
        this.selectOwnerTeam =
          this.userTenantsDropdownMap.find((tenant: UserTenantListMap) => tenant.value === this.jigBaseData.ownerTenantID) ||
          defaultTeamOption
        this.selectOwnerTeamMember =
          this.selectTenantUsers.find((user: JigOwnerUserListMap) => user.uid === this.jigBaseData.uid) || defaultTeamUserOption
      }
    }
  }

  @Watch('selectOwnerTeam')
  private async onSelectOwnerTeamChanged(value: UserTenantListMap, oldValue: UserTenantListMap) {
    if (this.selectOwnerTeam.value === TenantHelpers.InvalidTenantID) {
      return
    }

    if (!this.tenantsUsersMap.has(this.selectOwnerTeam.value) && !this.isLoadingTenantUsers) {
      this.isLoadingTenantUsers = true
      await this.loadTenantUsers({ tenantID: this.selectOwnerTeam.value })
      this.isLoadingTenantUsers = false
    }

    // Set default selected user, priority order:
    // 1. currently selected user
    // 2. current Jig owner user
    // 3. fallbacks: the first user in the list
    this.selectOwnerTeamMember =
      this.selectTenantUsers.find((user: JigOwnerUserListMap) => user.uid === this.selectOwnerTeamMember.uid) ||
      this.selectTenantUsers.find((user: JigOwnerUserListMap) => user.uid === this.jigBaseData.uid) ||
      this.selectTenantUsers[0]
  }

  protected created() {
    this.teamRules = ValidationRules.RequiredTeam
    this.teamUserRules = ValidationRules.RequiredTeamUser
  }

  private get selectTenantUsers(): JigOwnerUserListMap[] {
    return this.getUsersOfTenant(this.selectOwnerTeam.value)
  }

  private getUsersOfTenant(tenantID?: number) {
    let sourceUsers!: TenantUser[]
    if (tenantID != null && this.tenantsUsersMap.has(tenantID)) {
      sourceUsers = this.tenantsUsersMap.get(tenantID) as TenantUser[]
    } else {
      sourceUsers = this.tenantUsers
    }

    return sourceUsers.map((user: TenantUser) => ({
      text: user.name || user.username,
      value: user.email,
      uid: user.Uid,
    }))
  }

  private async transferJigPromptConfirmed() {
    if (!this.transferJigPromptIsValid || this.jigBaseData.id.length === 0) {
      return
    }

    this.isSubmitting = true
    const req: ChangeJigOwnerRequest = {
      JigId: this.jigBaseData.id,
      DesiredOwnerEmail: this.selectOwnerTeamMember.value,
      DesiredOwnerTenantID: this.selectOwnerTeam.value,
      action: 'transfer_ownership',
    }
    const originalJigUid = this.jigBaseData.uid
    const originalJigOwnerTenantID = this.jigBaseData.ownerTenantID
    if (await this.changeJigOwner(req)) {
      if (this.changeJigOwnerResult.status === 'success') {
        segmentEventTracking('TransferOwnership', {
          jigId: req.JigId,
          desiredOwnerEmail: req.DesiredOwnerEmail,
          desiredOwnerTenantID: req.DesiredOwnerTenantID,
          originalOwnerUid: originalJigUid,
          originalOwnerTenantID: originalJigOwnerTenantID,
          requestingUserEmail: this.$auth0.user.email,
          requestingTenantID: this.myTenant.ID,
        })
        this.$emit(JigConst.jigEvents.onJigOwnershipChanged, {
          reloadRequired: true,
        })
      } else {
        this.$emit(JigConst.jigEvents.onJigOwnershipFailed)
      }
    } else {
      this.$emit(JigConst.jigEvents.onJigOwnershipFailed)
    }

    this.isSubmitting = false
    this.ClosePrompt()
  }

  private userDisplayName(user: JigOwnerUserListMap) {
    if (user.uid === this.jigBaseData.uid) {
      return `${user.text} (owner)`
    }

    return user.text
  }

  public ShowPrompt() {
    this.$refs.transferJigPromptYesNo.ShowPrompt()
  }

  public ClosePrompt() {
    this.$refs.transferJigPromptYesNo.ClosePrompt()
    this.jigBaseData = defaultJigBaseData
    this.selectOwnerTeam = defaultTeamOption
    this.selectOwnerTeamMember = defaultTeamUserOption
  }
}
