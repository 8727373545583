
import { Component, Vue } from 'vue-property-decorator'

@Component({})

export default class TenantSwitcher extends Vue {
  public dialogVisible: boolean = false

  public ShowPrompt() {
    this.dialogVisible = true
  }

  public HidePrompt() {
    this.dialogVisible = false
  }
}
